var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var GrpcWebRtcLoopbackBiDiSession = /** @class */ (function () {
    function GrpcWebRtcLoopbackBiDiSession() {
    }
    GrpcWebRtcLoopbackBiDiSession.prototype.startSession = function (inputStream, outputStream) {
        return __awaiter(this, void 0, void 0, function () {
            var offer, answer;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loopbackRecordStream = new MediaStream();
                        this.loopbackPlaybackStream = new MediaStream();
                        this.rtcServerConnection = new RTCPeerConnection();
                        this.rtcClientConnection = new RTCPeerConnection();
                        this.rtcServerConnection.onicecandidate = function (e) {
                            e.candidate &&
                                _this.rtcClientConnection &&
                                _this.rtcClientConnection.addIceCandidate(new RTCIceCandidate(e.candidate));
                        };
                        this.rtcClientConnection.onicecandidate = function (e) {
                            e.candidate &&
                                _this.rtcServerConnection &&
                                _this.rtcServerConnection.addIceCandidate(new RTCIceCandidate(e.candidate));
                        };
                        // setup the loopback
                        this.rtcClientConnection.ontrack = function (e) {
                            var _a;
                            (_a = _this.loopbackPlaybackStream) === null || _a === void 0 ? void 0 : _a.addTrack(e.track);
                        };
                        this.rtcServerConnection.ontrack = function (e) {
                            var _a;
                            (_a = _this.loopbackRecordStream) === null || _a === void 0 ? void 0 : _a.addTrack(e.track);
                        };
                        inputStream.getTracks().forEach(function (track) {
                            var _a;
                            (_a = _this.rtcClientConnection) === null || _a === void 0 ? void 0 : _a.addTrack(track);
                        });
                        outputStream.getTracks().forEach(function (track) {
                            var _a;
                            (_a = _this.rtcServerConnection) === null || _a === void 0 ? void 0 : _a.addTrack(track);
                        });
                        return [4 /*yield*/, this.rtcServerConnection.createOffer(GrpcWebRtcLoopbackBiDiSession.OFFER_OPTIONS)];
                    case 1:
                        offer = _a.sent();
                        return [4 /*yield*/, this.rtcServerConnection.setLocalDescription(offer)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.rtcClientConnection.setRemoteDescription(offer)];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.rtcClientConnection.createAnswer()];
                    case 4:
                        answer = _a.sent();
                        return [4 /*yield*/, this.rtcClientConnection.setLocalDescription(answer)];
                    case 5:
                        _a.sent();
                        return [4 /*yield*/, this.rtcServerConnection.setRemoteDescription(answer)];
                    case 6:
                        _a.sent();
                        return [2 /*return*/, this];
                }
            });
        });
    };
    GrpcWebRtcLoopbackBiDiSession.prototype.getRecorderLoopBackStream = function () {
        if (!this.loopbackRecordStream) {
            throw new Error('No loopbackRecordStream available');
        }
        return this.loopbackRecordStream;
    };
    GrpcWebRtcLoopbackBiDiSession.prototype.getPlaybackLoopbackStream = function () {
        if (!this.loopbackPlaybackStream) {
            throw new Error('No loopbackPlaybackStream available');
        }
        return this.loopbackPlaybackStream;
    };
    GrpcWebRtcLoopbackBiDiSession.OFFER_OPTIONS = {
        offerVideo: false,
        offerAudio: true,
        offerToReceiveVideo: false,
        offerToReceiveAudio: true,
    };
    return GrpcWebRtcLoopbackBiDiSession;
}());
export { GrpcWebRtcLoopbackBiDiSession };
