var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { v4 } from 'uuid';
import { ActorType, ControlEventAction, DataChunkDataType, TextEventSourceType, } from '../../build/generated/proto/packets.pb';
import { EmotionBehavior } from '../entities/emotion-behavior.entity';
import { EmotionStrength } from '../entities/emotion-strength.entity';
import { InworlControlType, InworldPacket, InworldPacketType, } from '../entities/inworld_packet.entity';
var EventFactory = /** @class */ (function () {
    function EventFactory() {
        this.character = null;
        this.protoTimestampNow = function () { return new Date().toISOString(); };
    }
    EventFactory.prototype.getCurrentCharacter = function () {
        return this.character;
    };
    EventFactory.prototype.setCurrentCharacter = function (character) {
        this.character = character;
    };
    EventFactory.prototype.dataChunk = function (chunk, type) {
        return {
            packetId: {
                packetId: v4(),
            },
            timestamp: this.protoTimestampNow(),
            routing: this.routing(),
            dataChunk: { chunk: chunk, type: type },
        };
    };
    EventFactory.prototype.audioSessionStart = function () {
        return {
            packetId: {
                packetId: v4(),
            },
            timestamp: this.protoTimestampNow(),
            routing: this.routing(),
            control: { action: ControlEventAction.AUDIO_SESSION_START },
        };
    };
    EventFactory.prototype.audioSessionEnd = function () {
        return {
            packetId: {
                packetId: v4(),
            },
            timestamp: this.protoTimestampNow(),
            routing: this.routing(),
            control: { action: ControlEventAction.AUDIO_SESSION_END },
        };
    };
    EventFactory.prototype.ttsPlaybackStart = function () {
        return {
            packetId: {
                packetId: v4(),
            },
            timestamp: this.protoTimestampNow(),
            routing: this.routing(),
            control: { action: ControlEventAction.TTS_PLAYBACK_START },
        };
    };
    EventFactory.prototype.ttsPlaybackEnd = function () {
        return {
            packetId: {
                packetId: v4(),
            },
            timestamp: this.protoTimestampNow(),
            routing: this.routing(),
            control: { action: ControlEventAction.TTS_PLAYBACK_END },
        };
    };
    EventFactory.prototype.text = function (text) {
        return {
            packetId: {
                packetId: v4(),
                utteranceId: v4(),
                interactionId: v4(),
            },
            timestamp: this.protoTimestampNow(),
            routing: this.routing(),
            text: {
                sourceType: TextEventSourceType.TYPED_IN,
                text: text,
                final: true,
            },
        };
    };
    EventFactory.prototype.trigger = function (name) {
        return {
            packetId: {
                packetId: v4(),
                utteranceId: v4(),
                interactionId: v4(),
            },
            timestamp: this.protoTimestampNow(),
            routing: this.routing(),
            custom: { name: name },
        };
    };
    EventFactory.prototype.cancelResponse = function (cancelResponses) {
        return {
            packetId: {
                packetId: v4(),
            },
            timestamp: this.protoTimestampNow(),
            routing: this.routing(),
            cancelResponses: cancelResponses,
        };
    };
    EventFactory.prototype.convertToInworldPacket = function (packet) {
        var _a, _b;
        var packetId = packet.packetId;
        var routing = packet.routing;
        var source = routing.source;
        var target = routing.target;
        var type = this.getType(packet);
        var additionalPhonemeInfo = (_b = (_a = packet.dataChunk) === null || _a === void 0 ? void 0 : _a.additionalPhonemeInfo) !== null && _b !== void 0 ? _b : [];
        return new InworldPacket(__assign(__assign(__assign(__assign(__assign(__assign(__assign({ type: type, date: packet.timestamp, packetId: {
                packetId: packetId.packetId,
                utteranceId: packetId.utteranceId,
                interactionId: packetId.interactionId,
            }, routing: {
                source: {
                    name: source.name,
                    isPlayer: source.type === ActorType.PLAYER,
                    isCharacter: source.type === ActorType.AGENT,
                },
                target: {
                    name: target.name,
                    isPlayer: target.type === ActorType.PLAYER,
                    isCharacter: target.type === ActorType.AGENT,
                },
            } }, (type === InworldPacketType.TRIGGER && {
            trigger: {
                name: packet.custom.name,
            },
        })), (type === InworldPacketType.TEXT && {
            text: {
                text: packet.text.text,
                final: packet.text.final,
            },
        })), (type === InworldPacketType.AUDIO && {
            audio: {
                chunk: packet.dataChunk.chunk,
                additionalPhonemeInfo: (additionalPhonemeInfo !== null && additionalPhonemeInfo !== void 0 ? additionalPhonemeInfo : []).map(function (info) {
                    return ({
                        phoneme: info.phoneme,
                        startOffsetS: parseFloat(info.startOffset),
                    });
                }),
            },
        })), (type === InworldPacketType.CONTROL && {
            control: {
                type: this.getControlType(packet),
            },
        })), (type === InworldPacketType.SILENCE && {
            silence: {
                durationMs: parseInt(packet.dataChunk.durationMs, 10),
            },
        })), (type === InworldPacketType.EMOTION && {
            emotions: {
                behavior: new EmotionBehavior(packet.emotion.behavior),
                strength: new EmotionStrength(packet.emotion.strength),
            },
        })), (type === InworldPacketType.CANCEL_RESPONSE && {
            cancelResponses: {
                interactionId: packet.cancelResponses.interactionId,
                utteranceId: packet.cancelResponses.utteranceId,
            },
        })));
    };
    EventFactory.prototype.routing = function () {
        var _a;
        return {
            source: { type: ActorType.PLAYER },
            target: { type: ActorType.AGENT, name: (_a = this.character) === null || _a === void 0 ? void 0 : _a.getId() },
        };
    };
    EventFactory.prototype.getType = function (packet) {
        if (packet.text) {
            return InworldPacketType.TEXT;
        }
        else if (packet.dataChunk &&
            packet.dataChunk.type === DataChunkDataType.AUDIO) {
            return InworldPacketType.AUDIO;
        }
        else if (packet.dataChunk &&
            packet.dataChunk.type === DataChunkDataType.SILENCE) {
            return InworldPacketType.SILENCE;
        }
        else if (packet.custom) {
            return InworldPacketType.TRIGGER;
        }
        else if (packet.control) {
            return InworldPacketType.CONTROL;
        }
        else if (packet.emotion) {
            return InworldPacketType.EMOTION;
        }
        else if (packet.cancelResponses) {
            return InworldPacketType.CANCEL_RESPONSE;
        }
        else {
            return InworldPacketType.UNKNOWN;
        }
    };
    EventFactory.prototype.getControlType = function (packet) {
        switch (packet.control.action) {
            case ControlEventAction.INTERACTION_END:
                return InworlControlType.INTERACTION_END;
            case ControlEventAction.TTS_PLAYBACK_START:
                return InworlControlType.TTS_PLAYBACK_START;
            case ControlEventAction.TTS_PLAYBACK_END:
                return InworlControlType.TTS_PLAYBACK_END;
            default:
                return InworlControlType.UNKNOWN;
        }
    };
    return EventFactory;
}());
export { EventFactory };
