var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ConnectionState, } from '../common/interfaces';
import { CHAT_HISTORY_TYPE, InworldHistory, } from '../components/history';
import { Player } from '../components/sound/player';
import { WebSocketConnection, } from '../connection/web-socket.connection';
import { Character } from '../entities/character.entity';
import { EventFactory } from '../factories/event';
import { WorldEngineService } from './world_engine.service';
var TIME_DIFF_MS = 50 * 60 * 1000; // 5 minutes
var player = Player.getInstance();
var ConnectionService = /** @class */ (function () {
    function ConnectionService(props) {
        this.state = ConnectionState.INACTIVE;
        this.characters = [];
        this.intervals = [];
        this.eventFactory = new EventFactory();
        this.cancelReponses = {};
        this.history = new InworldHistory();
        this.connectionProps = props || {};
        this.initializeHandlers();
        this.initializeConnection();
    }
    ConnectionService.prototype.isActive = function () {
        return this.state === ConnectionState.ACTIVE;
    };
    ConnectionService.prototype.isAutoReconnected = function () {
        var _a, _b, _c;
        return (_c = (_b = (_a = this.connectionProps.config) === null || _a === void 0 ? void 0 : _a.connection) === null || _b === void 0 ? void 0 : _b.autoReconnect) !== null && _c !== void 0 ? _c : true;
    };
    ConnectionService.prototype.openManually = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                try {
                    if (this.isAutoReconnected()) {
                        throw Error('Impossible to open connection manually with `autoReconnect` enabled');
                    }
                    if (this.state !== ConnectionState.INACTIVE) {
                        throw Error('Connection is already open');
                    }
                    return [2 /*return*/, this.open()];
                }
                catch (err) {
                    this.onError(err);
                }
                return [2 /*return*/];
            });
        });
    };
    ConnectionService.prototype.close = function () {
        this.cancelScheduler();
        this.state = ConnectionState.INACTIVE;
        this.connection.close();
        this.clearQueue();
    };
    ConnectionService.prototype.getHistory = function () {
        return this.history.get();
    };
    ConnectionService.prototype.clearHistory = function () {
        this.history.clear();
    };
    ConnectionService.prototype.getEventFactory = function () {
        return this.eventFactory;
    };
    ConnectionService.prototype.getCharactersList = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadScene()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, this.characters];
                }
            });
        });
    };
    ConnectionService.prototype.open = function () {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        return [4 /*yield*/, this.loadScene()];
                    case 1:
                        _a.sent();
                        if (!(this.state === ConnectionState.LOADED)) return [3 /*break*/, 3];
                        this.state = ConnectionState.ACTIVATING;
                        return [4 /*yield*/, this.connection.open({
                                characters: this.characters.map(function (c) { return c.getId(); }),
                                session: this.session,
                            })];
                    case 2:
                        _a.sent();
                        this.scheduleDisconnect();
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        this.onError(err_1);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ConnectionService.prototype.send = function (getPacket) {
        return __awaiter(this, void 0, void 0, function () {
            var err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        this.cancelScheduler();
                        if (this.isActive()) {
                            return [2 /*return*/, this.write(getPacket)];
                        }
                        if (!this.isAutoReconnected()) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.open()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, this.write(getPacket)];
                    case 2: throw Error('Unable to send data due inactive connection');
                    case 3:
                        err_2 = _a.sent();
                        this.onError(err_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ConnectionService.prototype.loadCharactersList = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!!this.scene) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.loadScene()];
                    case 1:
                        _c.sent();
                        _c.label = 2;
                    case 2:
                        this.characters = (_b = (((_a = this.scene) === null || _a === void 0 ? void 0 : _a.agents) || [])) === null || _b === void 0 ? void 0 : _b.map(function (agent) {
                            return new Character({
                                id: agent.agentId,
                                resourceName: agent.brainName,
                                displayName: agent.givenName,
                                assets: {
                                    avatarImg: agent.characterAssets.avatarImg,
                                    avatarImgOriginal: agent.characterAssets.avatarImgOriginal,
                                    rpmModelUri: agent.characterAssets.rpmModelUri,
                                    rpmImageUriPortrait: agent.characterAssets.rpmImageUriPortrait,
                                    rpmImageUriPosture: agent.characterAssets.rpmImageUriPosture,
                                },
                            });
                        });
                        if (!this.getEventFactory().getCurrentCharacter() && this.characters[0]) {
                            this.getEventFactory().setCurrentCharacter(this.characters[0]);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ConnectionService.prototype.write = function (getPacket) {
        return __awaiter(this, void 0, void 0, function () {
            var inworldPacket, resolvePacket;
            var _this = this;
            return __generator(this, function (_a) {
                resolvePacket = function () {
                    return new Promise(function (resolve) {
                        var interval = setInterval(function () {
                            if (inworldPacket || _this.state === ConnectionState.INACTIVE) {
                                clearInterval(interval);
                                _this.intervals = _this.intervals.filter(function (i) { return i !== interval; });
                                resolve(inworldPacket);
                            }
                        }, 10);
                        _this.intervals.push(interval);
                    });
                };
                this.connection.write({
                    getPacket: getPacket,
                    afterWriting: function (packet) {
                        inworldPacket = _this.getEventFactory().convertToInworldPacket(packet);
                        _this.scheduleDisconnect();
                        if (inworldPacket.isText()) {
                            _this.interrupt(inworldPacket.packetId.interactionId);
                        }
                        _this.addPacketToHistory(inworldPacket);
                    },
                });
                return [2 /*return*/, resolvePacket()];
            });
        });
    };
    ConnectionService.prototype.loadScene = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, generateSessionToken, name, client, user, _b, sessionId, expirationTime, _c, engineService, _d, err_3;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        if (this.state === ConnectionState.LOADING)
                            return [2 /*return*/];
                        _a = this.connectionProps, generateSessionToken = _a.generateSessionToken, name = _a.name, client = _a.client, user = _a.user;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 7, , 8]);
                        _b = this.session || {}, sessionId = _b.sessionId, expirationTime = _b.expirationTime;
                        if (!(!expirationTime ||
                            new Date(expirationTime).getTime() - new Date().getTime() <=
                                TIME_DIFF_MS)) return [3 /*break*/, 3];
                        this.state = ConnectionState.LOADING;
                        _c = this;
                        return [4 /*yield*/, generateSessionToken()];
                    case 2:
                        _c.session = _e.sent();
                        // Reuse session id to keep context of previous conversation
                        if (sessionId) {
                            this.session = __assign(__assign({}, this.session), { sessionId: sessionId });
                        }
                        _e.label = 3;
                    case 3:
                        engineService = new WorldEngineService();
                        if (!!this.scene) return [3 /*break*/, 6];
                        _d = this;
                        return [4 /*yield*/, engineService.loadScene({
                                config: this.connectionProps.config,
                                session: this.session,
                                name: name,
                                user: user,
                                client: client,
                            })];
                    case 4:
                        _d.scene = _e.sent();
                        return [4 /*yield*/, this.loadCharactersList()];
                    case 5:
                        _e.sent();
                        _e.label = 6;
                    case 6:
                        if ([ConnectionState.LOADING, ConnectionState.INACTIVE].includes(this.state)) {
                            this.state = ConnectionState.LOADED;
                        }
                        return [3 /*break*/, 8];
                    case 7:
                        err_3 = _e.sent();
                        this.onError(err_3);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    ConnectionService.prototype.scheduleDisconnect = function () {
        var _this = this;
        var _a, _b;
        if ((_b = (_a = this.connectionProps.config) === null || _a === void 0 ? void 0 : _a.connection) === null || _b === void 0 ? void 0 : _b.disconnectTimeout) {
            this.cancelScheduler();
            this.disconnectTimeoutId = setTimeout(function () { return _this.close(); }, this.connectionProps.config.connection.disconnectTimeout);
        }
    };
    ConnectionService.prototype.cancelScheduler = function () {
        if (this.disconnectTimeoutId) {
            clearTimeout(this.disconnectTimeoutId);
        }
    };
    ConnectionService.prototype.initializeHandlers = function () {
        var _this = this;
        var _a = this.connectionProps, onError = _a.onError, onReady = _a.onReady, onDisconnect = _a.onDisconnect, grpcAudioPlayer = _a.grpcAudioPlayer, webRtcLoopbackBiDiSession = _a.webRtcLoopbackBiDiSession;
        this.onReady = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, webRtcLoopbackBiDiSession.startSession(new MediaStream(), grpcAudioPlayer.getPlaybackStream())];
                    case 1:
                        _a.sent();
                        player.setStream(webRtcLoopbackBiDiSession.getPlaybackLoopbackStream());
                        this.state = ConnectionState.ACTIVE;
                        onReady === null || onReady === void 0 ? void 0 : onReady();
                        return [2 /*return*/];
                }
            });
        }); };
        this.onDisconnect = function () {
            _this.state = ConnectionState.INACTIVE;
            onDisconnect === null || onDisconnect === void 0 ? void 0 : onDisconnect();
        };
        this.onError = onError !== null && onError !== void 0 ? onError : (function (event) { return console.error(event); });
        this.onMessage = function (packet) { return __awaiter(_this, void 0, void 0, function () {
            var _a, onMessage, grpcAudioPlayer, inworldPacket, interactionId, updated;
            var _this = this;
            return __generator(this, function (_b) {
                _a = this.connectionProps, onMessage = _a.onMessage, grpcAudioPlayer = _a.grpcAudioPlayer;
                inworldPacket = this.eventFactory.convertToInworldPacket(packet);
                interactionId = inworldPacket.packetId.interactionId;
                // Don't pass text packet outside for interrupred interaction.
                if (inworldPacket.isText() &&
                    !inworldPacket.routing.source.isPlayer &&
                    this.cancelReponses[interactionId]) {
                    updated = this.updatePacketInHistory(inworldPacket);
                    if (updated) {
                        // Pass packet to external callback.
                        onMessage === null || onMessage === void 0 ? void 0 : onMessage(inworldPacket);
                    }
                    else {
                        this.sendCancelResponses({
                            interactionId: interactionId,
                            utteranceId: [packet.packetId.utteranceId],
                        });
                    }
                    return [2 /*return*/];
                }
                // Send cancel response event in case of player talking.
                if (inworldPacket.isText() && inworldPacket.routing.source.isPlayer) {
                    this.interrupt(inworldPacket.packetId.interactionId);
                }
                // Play audio or silence.
                if (inworldPacket.isAudio() || inworldPacket.isSilence()) {
                    if (!this.cancelReponses[interactionId]) {
                        grpcAudioPlayer.addToQueue({
                            packet: inworldPacket,
                            onBeforePlaying: function (packet) {
                                return _this.displayPlacketInHistory(packet, CHAT_HISTORY_TYPE.ACTOR);
                            },
                            onAfterPlaying: function (packet) {
                                _this.displayPlacketInHistory(packet, CHAT_HISTORY_TYPE.INTERACTION_END);
                            },
                        });
                    }
                }
                // Delete info about cancel responses on interaction end.
                if (inworldPacket.isInteractionEnd()) {
                    delete this.cancelReponses[interactionId];
                }
                // Add packet to history.
                this.addPacketToHistory(inworldPacket);
                // Pass packet to external callback.
                onMessage === null || onMessage === void 0 ? void 0 : onMessage(inworldPacket);
                return [2 /*return*/];
            });
        }); };
    };
    ConnectionService.prototype.initializeConnection = function () {
        var config = this.connectionProps.config;
        var props = {
            config: config,
            onDisconnect: this.onDisconnect,
            onReady: this.onReady,
            onError: this.onError,
            onMessage: this.onMessage,
        };
        this.connection = new WebSocketConnection(props);
    };
    ConnectionService.prototype.interrupt = function (interactionId) {
        var _a = this.connectionProps, grpcAudioPlayer = _a.grpcAudioPlayer, config = _a.config;
        if (!(config === null || config === void 0 ? void 0 : config.capabilities.interruptions))
            return;
        var packets = grpcAudioPlayer.excludeCurrentInteractionPackets(interactionId);
        if (!grpcAudioPlayer.isCurrentPacket({ interactionId: interactionId })) {
            grpcAudioPlayer.stop();
        }
        if (packets.length) {
            var interactionId_1 = packets[0].packetId.interactionId;
            this.sendCancelResponses({
                interactionId: interactionId_1,
                utteranceId: packets.map(function (packet) { return packet.packetId.utteranceId; }),
            });
        }
    };
    ConnectionService.prototype.sendCancelResponses = function (cancelReponses) {
        var _a;
        var _this = this;
        var _b;
        if (cancelReponses.interactionId) {
            this.send(function () { return _this.getEventFactory().cancelResponse(cancelReponses); });
            this.cancelReponses = __assign(__assign({}, this.cancelReponses), (_a = {}, _a[cancelReponses.interactionId] = true, _a));
            this.history.filter({
                utteranceId: (_b = cancelReponses.utteranceId) !== null && _b !== void 0 ? _b : [],
                interactionId: cancelReponses.interactionId,
            });
        }
    };
    ConnectionService.prototype.addPacketToHistory = function (packet) {
        var _a, _b;
        var changed = this.history.addOrUpdate({
            grpcAudioPlayer: this.connectionProps.grpcAudioPlayer,
            characters: this.characters,
            packet: packet,
        });
        if (changed) {
            (_b = (_a = this.connectionProps).onHistoryChange) === null || _b === void 0 ? void 0 : _b.call(_a, this.getHistory());
        }
    };
    ConnectionService.prototype.updatePacketInHistory = function (packet) {
        var changed = this.history.update(packet);
        if (changed) {
            this.connectionProps.onHistoryChange(this.getHistory());
        }
        return changed;
    };
    ConnectionService.prototype.displayPlacketInHistory = function (packet, type) {
        var changed = this.history.display(packet, type);
        if (changed) {
            this.connectionProps.onHistoryChange(this.getHistory());
        }
    };
    ConnectionService.prototype.clearQueue = function () {
        this.intervals.forEach(function (i) {
            clearInterval(i);
        });
        this.intervals = [];
    };
    return ConnectionService;
}());
export { ConnectionService };
