var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { GRPC_HOSTNAME } from '../common/constants';
import { GrpcAudioPlayback } from '../components/sound/grpc_audio.playback';
import { GrpcAudioRecorder } from '../components/sound/grpc_audio.recorder';
import { GrpcWebRtcLoopbackBiDiSession } from '../components/sound/grpc_web_rtc_loopback_bidi.session';
import { ConnectionService } from '../services/connection.service';
import { InworldConnectionService } from '../services/inworld_connection.service';
var InworldClient = /** @class */ (function () {
    function InworldClient() {
        this.scene = '';
        this.config = {
            capabilities: this.ensureCapabilities(),
        };
    }
    InworldClient.prototype.setUser = function (user) {
        this.user = {
            id: user.id,
            name: user.fullName,
        };
        return this;
    };
    InworldClient.prototype.setClient = function (client) {
        this.client = {
            id: client.id,
        };
        return this;
    };
    InworldClient.prototype.setGenerateSessionToken = function (generateSessionToken) {
        this.generateSessionToken = generateSessionToken;
        return this;
    };
    InworldClient.prototype.setConfiguration = function (config) {
        var _a = config.connection, connection = _a === void 0 ? {} : _a, _b = config.capabilities, capabilities = _b === void 0 ? {} : _b;
        var gateway = connection.gateway;
        this.config = __assign(__assign({}, config), { connection: __assign(__assign({}, connection), { gateway: this.ensureGateway(GRPC_HOSTNAME, gateway) }), capabilities: this.ensureCapabilities(capabilities) });
        return this;
    };
    InworldClient.prototype.setScene = function (name) {
        this.scene = name;
        return this;
    };
    InworldClient.prototype.setOnDisconnect = function (fn) {
        this.onDisconnect = fn;
        return this;
    };
    InworldClient.prototype.setOnError = function (fn) {
        this.onError = fn;
        return this;
    };
    InworldClient.prototype.setOnMessage = function (fn) {
        this.onMessage = fn;
        return this;
    };
    InworldClient.prototype.setOnReady = function (fn) {
        this.onReady = fn;
        return this;
    };
    InworldClient.prototype.setOnHistoryChange = function (fn) {
        this.onHistoryChange = fn;
        return this;
    };
    InworldClient.prototype.setOnPhoneme = function (fn) {
        this.onPhoneme = fn;
        return this;
    };
    InworldClient.prototype.setOnAfterPlaying = function (fn) {
        this.onAfterPlaying = fn;
        return this;
    };
    InworldClient.prototype.setOnBeforePlaying = function (fn) {
        this.onBeforePlaying = fn;
        return this;
    };
    InworldClient.prototype.setOnStopPlaying = function (fn) {
        this.onStopPlaying = fn;
        return this;
    };
    InworldClient.prototype.build = function () {
        this.validate();
        var webRtcLoopbackBiDiSession = new GrpcWebRtcLoopbackBiDiSession();
        var grpcAudioRecorder = new GrpcAudioRecorder();
        var grpcAudioPlayer = new GrpcAudioPlayback({
            onAfterPlaying: this.onAfterPlaying,
            onBeforePlaying: this.onBeforePlaying,
            onStopPlaying: this.onStopPlaying,
            onPhoneme: this.onPhoneme,
        });
        var connection = new ConnectionService({
            grpcAudioPlayer: grpcAudioPlayer,
            webRtcLoopbackBiDiSession: webRtcLoopbackBiDiSession,
            name: this.scene,
            user: this.user,
            client: this.client,
            config: this.config,
            onError: this.onError,
            onReady: this.onReady,
            onMessage: this.onMessage,
            onHistoryChange: this.onHistoryChange,
            onDisconnect: this.onDisconnect,
            generateSessionToken: this.generateSessionToken,
        });
        return new InworldConnectionService({
            connection: connection,
            grpcAudioPlayer: grpcAudioPlayer,
            grpcAudioRecorder: grpcAudioRecorder,
            webRtcLoopbackBiDiSession: webRtcLoopbackBiDiSession,
        });
    };
    InworldClient.prototype.ensureCapabilities = function (capabilities) {
        var _a, _b, _c, _d, _e, _f;
        return {
            audio: (_a = capabilities === null || capabilities === void 0 ? void 0 : capabilities.audio) !== null && _a !== void 0 ? _a : true,
            emotions: (_b = capabilities === null || capabilities === void 0 ? void 0 : capabilities.emotions) !== null && _b !== void 0 ? _b : false,
            interruptions: (_c = capabilities === null || capabilities === void 0 ? void 0 : capabilities.interruptions) !== null && _c !== void 0 ? _c : false,
            phonemeInfo: (_d = capabilities === null || capabilities === void 0 ? void 0 : capabilities.phonemes) !== null && _d !== void 0 ? _d : false,
            silenceEvents: (_e = capabilities === null || capabilities === void 0 ? void 0 : capabilities.silence) !== null && _e !== void 0 ? _e : false,
            text: true,
            triggers: true,
            turnBasedStt: (_f = capabilities === null || capabilities === void 0 ? void 0 : capabilities.turnBasedStt) !== null && _f !== void 0 ? _f : false,
        };
    };
    InworldClient.prototype.ensureGateway = function (hostname, gateway) {
        var _a, _b;
        return {
            hostname: (_a = gateway === null || gateway === void 0 ? void 0 : gateway.hostname) !== null && _a !== void 0 ? _a : hostname,
            ssl: (_b = gateway === null || gateway === void 0 ? void 0 : gateway.ssl) !== null && _b !== void 0 ? _b : true,
        };
    };
    InworldClient.prototype.validate = function () {
        if (!this.scene) {
            throw Error('Scene name is required');
        }
    };
    return InworldClient;
}());
export { InworldClient };
