import { EmotionEventStrength } from '../../build/generated/proto/packets.pb';
var EmotionStrength = /** @class */ (function () {
    function EmotionStrength(strength) {
        this.strength = strength;
    }
    EmotionStrength.prototype.isWeak = function () {
        return this.strength === EmotionEventStrength.WEAK;
    };
    EmotionStrength.prototype.isStrong = function () {
        return this.strength === EmotionEventStrength.STRONG;
    };
    return EmotionStrength;
}());
export { EmotionStrength };
