var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var GrpcAudioRecorder = /** @class */ (function () {
    function GrpcAudioRecorder() {
        var _this = this;
        this.currentMediaStream = null;
        this.processor = null;
        this.leftChannel = [];
        this.recordingLength = 0;
        this.interval = null;
        this.listener = null;
        this.onAudioProcess = function (e) {
            var samples = e.inputBuffer.getChannelData(0);
            // we clone the samples to not loose them
            _this.leftChannel.push(new Float32Array(samples));
            _this.recordingLength += GrpcAudioRecorder.BUFFER_SIZE_BYTES;
        };
        this.intervalFunction = function () {
            var PCM32fSamples = _this.mergeBuffers(_this.leftChannel, _this.recordingLength);
            // reset "buffer" on each iteration
            _this.leftChannel = [];
            _this.recordingLength = 0;
            // convert to Int16 (we are working in LINEAR16 currently)
            var PCM16iSamples = Int16Array.from(PCM32fSamples, function (k) { return 32767 * Math.min(1, k); });
            if (!_this.listener) {
                return;
            }
            _this.listener(_this.arrayBufferToBase64(PCM16iSamples.buffer));
        };
    }
    GrpcAudioRecorder.prototype.stopConvertion = function () {
        var _a;
        if (!this.currentMediaStream) {
            return;
        }
        this.currentMediaStream.getTracks().forEach(function (track) { return track.stop(); });
        if (this.interval) {
            clearInterval(this.interval);
        }
        (_a = this.processor) === null || _a === void 0 ? void 0 : _a.disconnect();
        this.leftChannel = [];
        this.recordingLength = 0;
        this.interval = null;
    };
    GrpcAudioRecorder.prototype.isRecording = function () {
        return this.interval != null;
    };
    // Consumes stream that is coming out of local webrtc loopback and converts it to the messages for the server.
    GrpcAudioRecorder.prototype.startConvertion = function (stream, listener) {
        return __awaiter(this, void 0, void 0, function () {
            var context, source;
            return __generator(this, function (_a) {
                this.listener = listener;
                context = new AudioContext({
                    sampleRate: GrpcAudioRecorder.SAMPLE_RATE_HZ,
                });
                source = context.createMediaStreamSource(stream);
                // need to keep track of this two in order to properly disconnect later on;
                this.currentMediaStream = stream;
                this.processor = context.createScriptProcessor(GrpcAudioRecorder.BUFFER_SIZE_BYTES, 
                /* input channels = */ 1, 
                /* output channels = */ 1);
                source.connect(this.processor);
                this.processor.connect(context.destination);
                this.processor.onaudioprocess = this.onAudioProcess;
                this.interval = setInterval(this.intervalFunction, GrpcAudioRecorder.INTERVAL_TIMEOUT_MS);
                return [2 /*return*/];
            });
        });
    };
    GrpcAudioRecorder.prototype.mergeBuffers = function (channelBuffer, recordingLength) {
        var result = new Float32Array(recordingLength);
        var offset = 0;
        for (var i = 0; i < channelBuffer.length; i++) {
            result.set(channelBuffer[i], offset);
            offset += channelBuffer[i].length;
        }
        return Array.prototype.slice.call(result);
    };
    GrpcAudioRecorder.prototype.arrayBufferToBase64 = function (buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var length = bytes.byteLength;
        for (var i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    };
    GrpcAudioRecorder.SAMPLE_RATE_HZ = 16000;
    GrpcAudioRecorder.BUFFER_SIZE_BYTES = 2048;
    GrpcAudioRecorder.INTERVAL_TIMEOUT_MS = 200;
    return GrpcAudioRecorder;
}());
export { GrpcAudioRecorder };
