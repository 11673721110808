import { EmotionEventSpaffCode } from '../../build/generated/proto/packets.pb';
var EmotionBehavior = /** @class */ (function () {
    function EmotionBehavior(behavior) {
        this.behavior = behavior;
    }
    EmotionBehavior.prototype.isNeutral = function () {
        return this.behavior === EmotionEventSpaffCode.NEUTRAL;
    };
    EmotionBehavior.prototype.isDisgust = function () {
        return this.behavior === EmotionEventSpaffCode.DISGUST;
    };
    EmotionBehavior.prototype.isContempt = function () {
        return this.behavior === EmotionEventSpaffCode.CONTEMPT;
    };
    EmotionBehavior.prototype.isBelligerence = function () {
        return this.behavior === EmotionEventSpaffCode.BELLIGERENCE;
    };
    EmotionBehavior.prototype.isDomineering = function () {
        return this.behavior === EmotionEventSpaffCode.DOMINEERING;
    };
    EmotionBehavior.prototype.isCriticism = function () {
        return this.behavior === EmotionEventSpaffCode.CRITICISM;
    };
    EmotionBehavior.prototype.isAnger = function () {
        return this.behavior === EmotionEventSpaffCode.ANGER;
    };
    EmotionBehavior.prototype.isTension = function () {
        return this.behavior === EmotionEventSpaffCode.TENSION;
    };
    EmotionBehavior.prototype.isTenseHumor = function () {
        return this.behavior === EmotionEventSpaffCode.TENSE_HUMOR;
    };
    EmotionBehavior.prototype.isDefensiveness = function () {
        return this.behavior === EmotionEventSpaffCode.DEFENSIVENESS;
    };
    EmotionBehavior.prototype.isWhining = function () {
        return this.behavior === EmotionEventSpaffCode.WHINING;
    };
    EmotionBehavior.prototype.isSadness = function () {
        return this.behavior === EmotionEventSpaffCode.SADNESS;
    };
    EmotionBehavior.prototype.isStonewalling = function () {
        return this.behavior === EmotionEventSpaffCode.STONEWALLING;
    };
    EmotionBehavior.prototype.isInterest = function () {
        return this.behavior === EmotionEventSpaffCode.INTEREST;
    };
    EmotionBehavior.prototype.isValidation = function () {
        return this.behavior === EmotionEventSpaffCode.VALIDATION;
    };
    EmotionBehavior.prototype.isAffection = function () {
        return this.behavior === EmotionEventSpaffCode.AFFECTION;
    };
    EmotionBehavior.prototype.isHumor = function () {
        return this.behavior === EmotionEventSpaffCode.HUMOR;
    };
    EmotionBehavior.prototype.isSurprise = function () {
        return this.behavior === EmotionEventSpaffCode.SURPRISE;
    };
    EmotionBehavior.prototype.isJoy = function () {
        return this.behavior === EmotionEventSpaffCode.JOY;
    };
    return EmotionBehavior;
}());
export { EmotionBehavior };
