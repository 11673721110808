var Character = /** @class */ (function () {
    function Character(props) {
        this.id = props.id;
        this.resourceName = props.resourceName;
        this.displayName = props.displayName;
        this.assets = props.assets;
    }
    Character.prototype.getId = function () {
        return this.id;
    };
    Character.prototype.getResourceName = function () {
        return this.resourceName;
    };
    Character.prototype.getDisplayName = function () {
        return this.displayName;
    };
    Character.prototype.getAssets = function () {
        return this.assets;
    };
    return Character;
}());
export { Character };
