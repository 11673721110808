var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { decode } from 'base64-arraybuffer';
var GrpcAudioPlayback = /** @class */ (function () {
    function GrpcAudioPlayback(props) {
        var _this = this;
        this.audioQueue = [];
        this.isPlaying = false;
        this.playbackAudioContext = new AudioContext({ sampleRate: 16000 });
        this.destinationNode = this.playbackAudioContext.createMediaStreamDestination();
        this.playQueue = function () { return __awaiter(_this, void 0, void 0, function () {
            var audioBuffer, packet, durationSec;
            var _a, _b, _c, _d, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        if (!this.audioQueue.length) {
                            this.getSourceNode().buffer = null;
                            this.isPlaying = false;
                            (_a = this.onStopPlaying) === null || _a === void 0 ? void 0 : _a.call(this);
                            return [2 /*return*/];
                        }
                        this.currentItem = this.audioQueue.shift();
                        this.isPlaying = true;
                        packet = (this.currentItem || {}).packet;
                        if (!((packet === null || packet === void 0 ? void 0 : packet.isSilence()) && packet.silence.durationMs)) return [3 /*break*/, 1];
                        durationSec = packet.silence.durationMs / 1000;
                        audioBuffer = this.playbackAudioContext.createBuffer(2, this.playbackAudioContext.sampleRate * durationSec, this.playbackAudioContext.sampleRate);
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.playbackAudioContext.decodeAudioData(
                        // convertion due to grpc-gateway ws specific.
                        decode(packet.audio.chunk))];
                    case 2:
                        audioBuffer = _f.sent();
                        _f.label = 3;
                    case 3:
                        this.stop();
                        this.getSourceNode().buffer = audioBuffer;
                        if (packet.isAudio() && packet.audio.additionalPhonemeInfo) {
                            (_b = this.onPhoneme) === null || _b === void 0 ? void 0 : _b.call(this, packet.audio.additionalPhonemeInfo);
                        }
                        this.getSourceNode().start();
                        (_c = this.onBeforePlaying) === null || _c === void 0 ? void 0 : _c.call(this, packet);
                        (_e = (_d = this.currentItem).onBeforePlaying) === null || _e === void 0 ? void 0 : _e.call(_d, packet);
                        return [2 /*return*/];
                }
            });
        }); };
        this.onAfterPlaying = props === null || props === void 0 ? void 0 : props.onAfterPlaying;
        this.onBeforePlaying = props === null || props === void 0 ? void 0 : props.onBeforePlaying;
        this.onStopPlaying = props === null || props === void 0 ? void 0 : props.onStopPlaying;
        this.onPhoneme = props === null || props === void 0 ? void 0 : props.onPhoneme;
    }
    GrpcAudioPlayback.prototype.getIsActive = function () {
        return this.isPlaying;
    };
    GrpcAudioPlayback.prototype.addToQueue = function (item) {
        this.audioQueue.push(item);
        if (!this.isPlaying) {
            this.playQueue();
        }
    };
    GrpcAudioPlayback.prototype.clearQueue = function () {
        this.isPlaying = false;
        this.audioQueue = [];
    };
    GrpcAudioPlayback.prototype.excludeCurrentInteractionPackets = function (exceptInteractionId) {
        var _this = this;
        var toExlcude = this.audioQueue.filter(function (item) {
            var _a, _b;
            return item.packet.packetId.interactionId !== exceptInteractionId &&
                item.packet.packetId.interactionId ===
                    ((_b = (_a = _this.currentItem) === null || _a === void 0 ? void 0 : _a.packet) === null || _b === void 0 ? void 0 : _b.packetId.interactionId);
        });
        if (toExlcude.length) {
            this.audioQueue = this.audioQueue.filter(function (item) {
                var _a, _b;
                return item.packet.packetId.interactionId !==
                    ((_b = (_a = _this.currentItem) === null || _a === void 0 ? void 0 : _a.packet) === null || _b === void 0 ? void 0 : _b.packetId.interactionId);
            });
        }
        var result = __spreadArray([], toExlcude, true);
        if (this.currentItem &&
            this.currentItem.packet.packetId.interactionId !== exceptInteractionId)
            result.unshift(this.currentItem);
        return result.map(function (item) { return item.packet; });
    };
    GrpcAudioPlayback.prototype.hasPacketInQueue = function (props) {
        var interactionId = props.interactionId, utteranceId = props.utteranceId;
        return !!this.audioQueue.find(function (item) {
            var result = true;
            var packetId = item.packet.packetId;
            if (interactionId && packetId.interactionId !== interactionId) {
                result = false;
            }
            if (utteranceId && packetId.utteranceId !== utteranceId) {
                result = false;
            }
            return result;
        });
    };
    GrpcAudioPlayback.prototype.isCurrentPacket = function (props) {
        var _a;
        var interactionId = props.interactionId, utteranceId = props.utteranceId;
        var packetId = (((_a = this.currentItem) === null || _a === void 0 ? void 0 : _a.packet) || {}).packetId;
        var result = true;
        if (interactionId && (packetId === null || packetId === void 0 ? void 0 : packetId.interactionId) !== interactionId) {
            result = false;
        }
        if (utteranceId && packetId.utteranceId !== utteranceId) {
            result = false;
        }
        return result;
    };
    GrpcAudioPlayback.prototype.getPlaybackStream = function () {
        return this.destinationNode.stream;
    };
    GrpcAudioPlayback.prototype.stop = function () {
        this.getSourceNode().disconnect();
        delete this.audioBufferSourceNode;
    };
    GrpcAudioPlayback.prototype.init = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    // Way past safari autoplay. Should be called from inside direct user interaction.
                    return [4 /*yield*/, this.playbackAudioContext.resume().catch(console.error)];
                    case 1:
                        // Way past safari autoplay. Should be called from inside direct user interaction.
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    GrpcAudioPlayback.prototype.getSourceNode = function () {
        var _this = this;
        if (!this.audioBufferSourceNode) {
            this.audioBufferSourceNode = new AudioBufferSourceNode(this.playbackAudioContext);
            this.audioBufferSourceNode.connect(this.destinationNode);
            this.audioBufferSourceNode.onended = function () {
                var _a, _b, _c;
                (_a = _this.onAfterPlaying) === null || _a === void 0 ? void 0 : _a.call(_this, _this.currentItem.packet);
                (_c = (_b = _this.currentItem).onAfterPlaying) === null || _c === void 0 ? void 0 : _c.call(_b, _this.currentItem.packet);
                _this.currentItem = undefined;
                _this.playQueue();
            };
        }
        return this.audioBufferSourceNode;
    };
    return GrpcAudioPlayback;
}());
export { GrpcAudioPlayback };
