var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var SESSION_PATH = '/v1/session/default';
var WebSocketConnection = /** @class */ (function () {
    function WebSocketConnection(props) {
        this.packetQueue = [];
        this.connectionProps = props;
    }
    WebSocketConnection.prototype.isActive = function () {
        var _a;
        return ((_a = this.ws) === null || _a === void 0 ? void 0 : _a.readyState) === WebSocket.OPEN;
    };
    WebSocketConnection.prototype.open = function (_a) {
        var session = _a.session;
        return __awaiter(this, void 0, void 0, function () {
            var _b, config, onError, onDisconnect, onMessage, onReady;
            return __generator(this, function (_c) {
                _b = this.connectionProps, config = _b.config, onError = _b.onError, onDisconnect = _b.onDisconnect, onMessage = _b.onMessage, onReady = _b.onReady;
                this.ws = this.createWebSocket(__assign(__assign({ config: config, session: session, onError: onError, onDisconnect: onDisconnect }, (onReady && { onReady: onReady })), (onMessage && { onMessage: onMessage })));
                return [2 /*return*/];
            });
        });
    };
    WebSocketConnection.prototype.close = function () {
        var _a, _b, _c, _d, _e, _f, _g;
        if (this.connectionProps.onReady) {
            (_a = this.ws) === null || _a === void 0 ? void 0 : _a.removeEventListener('open', this.onReady);
        }
        if (this.connectionProps.onError) {
            (_b = this.ws) === null || _b === void 0 ? void 0 : _b.removeEventListener('error', this.connectionProps.onError);
        }
        if (this.connectionProps.onDisconnect) {
            (_c = this.ws) === null || _c === void 0 ? void 0 : _c.removeEventListener('close', this.connectionProps.onDisconnect);
        }
        (_d = this.ws) === null || _d === void 0 ? void 0 : _d.removeEventListener('message', this.onMessage);
        if (this.isActive()) {
            (_e = this.ws) === null || _e === void 0 ? void 0 : _e.close();
            (_g = (_f = this.connectionProps).onDisconnect) === null || _g === void 0 ? void 0 : _g.call(_f);
        }
        this.ws = null;
        this.packetQueue = [];
    };
    WebSocketConnection.prototype.write = function (item) {
        var _a, _b;
        // There's time gap between connection creation and connection activation.
        // So put packets to queue and send them `onReady` event.
        if (this.isActive()) {
            var packet = item.getPacket();
            (_a = this.ws) === null || _a === void 0 ? void 0 : _a.send(JSON.stringify(packet));
            (_b = item.afterWriting) === null || _b === void 0 ? void 0 : _b.call(item, packet);
        }
        else {
            this.packetQueue.push(item);
        }
    };
    WebSocketConnection.prototype.createWebSocket = function (props) {
        var config = props.config, session = props.session, onDisconnect = props.onDisconnect, onError = props.onError;
        var _a = config.connection.gateway, hostname = _a.hostname, ssl = _a.ssl;
        var url = "".concat(ssl ? 'wss' : 'ws', "://").concat(hostname).concat(SESSION_PATH, "?session_id=").concat(session.sessionId);
        var ws = new WebSocket(url, [session.type, session.token]);
        ws.addEventListener('open', this.onReady.bind(this));
        ws.addEventListener('message', this.onMessage.bind(this));
        if (onDisconnect) {
            ws.addEventListener('close', onDisconnect);
        }
        if (onError) {
            ws.addEventListener('error', onError);
        }
        return ws;
    };
    WebSocketConnection.prototype.onMessage = function (event) {
        var _a = this.connectionProps, onError = _a.onError, onMessage = _a.onMessage;
        var payload = JSON.parse(event.data);
        if (payload.error && onError) {
            onError(payload.error);
        }
        if (!payload.error && onMessage) {
            onMessage(payload.result);
        }
    };
    WebSocketConnection.prototype.onReady = function () {
        var _this = this;
        setTimeout(function () {
            _this.packetQueue.forEach(function (item) { return _this.write(item); });
            _this.packetQueue = [];
            if (_this.connectionProps.onReady) {
                _this.connectionProps.onReady();
            }
        }, 0);
    };
    return WebSocketConnection;
}());
export { WebSocketConnection };
