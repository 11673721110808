export var InworldPacketType;
(function (InworldPacketType) {
    InworldPacketType["UNKNOWN"] = "UNKNOWN";
    InworldPacketType["TEXT"] = "TEXT";
    InworldPacketType["AUDIO"] = "AUDIO";
    InworldPacketType["TRIGGER"] = "TRIGGER";
    InworldPacketType["EMOTION"] = "EMOTION";
    InworldPacketType["CONTROL"] = "CONTROL";
    InworldPacketType["SILENCE"] = "SILENCE";
    InworldPacketType["CANCEL_RESPONSE"] = "CANCEL_RESPONSE";
})(InworldPacketType || (InworldPacketType = {}));
export var InworlControlType;
(function (InworlControlType) {
    InworlControlType["UNKNOWN"] = "UNKNOWN";
    InworlControlType["INTERACTION_END"] = "INTERACTION_END";
    InworlControlType["TTS_PLAYBACK_START"] = "TTS_PLAYBACK_START";
    InworlControlType["TTS_PLAYBACK_END"] = "TTS_PLAYBACK_END";
})(InworlControlType || (InworlControlType = {}));
var InworldPacket = /** @class */ (function () {
    function InworldPacket(props) {
        this.packetId = props.packetId;
        this.routing = props.routing;
        this.date = props.date;
        this.type = props.type;
        if (this.isText()) {
            this.text = props.text;
        }
        if (this.isAudio()) {
            this.audio = props.audio;
        }
        if (this.isControl()) {
            this.control = props.control;
        }
        if (this.isEmotion()) {
            this.emotions = props.emotions;
        }
        if (this.isTrigger()) {
            this.trigger = props.trigger;
        }
        if (this.isSilence()) {
            this.silence = props.silence;
        }
        if (this.isCancelResponse()) {
            this.cancelResponses = props.cancelResponses;
        }
    }
    InworldPacket.prototype.isText = function () {
        return this.type === InworldPacketType.TEXT;
    };
    InworldPacket.prototype.isAudio = function () {
        return this.type === InworldPacketType.AUDIO;
    };
    InworldPacket.prototype.isControl = function () {
        return this.type === InworldPacketType.CONTROL;
    };
    InworldPacket.prototype.isTrigger = function () {
        return this.type === InworldPacketType.TRIGGER;
    };
    InworldPacket.prototype.isEmotion = function () {
        return this.type === InworldPacketType.EMOTION;
    };
    InworldPacket.prototype.isInteractionEnd = function () {
        return (this.isControl() &&
            this.control.type === InworlControlType.INTERACTION_END);
    };
    InworldPacket.prototype.isTTSPlaybackStart = function () {
        return (this.isControl() &&
            this.control.type === InworlControlType.TTS_PLAYBACK_START);
    };
    InworldPacket.prototype.isTTSPlaybackEnd = function () {
        return (this.isControl() &&
            this.control.type === InworlControlType.TTS_PLAYBACK_END);
    };
    InworldPacket.prototype.isSilence = function () {
        return this.type === InworldPacketType.SILENCE;
    };
    InworldPacket.prototype.isCancelResponse = function () {
        return this.type === InworldPacketType.CANCEL_RESPONSE;
    };
    return InworldPacket;
}());
export { InworldPacket };
