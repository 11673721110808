/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/
export var Playback;
(function (Playback) {
    Playback["UNSPECIFIED"] = "UNSPECIFIED";
    Playback["INTERACTION"] = "INTERACTION";
    Playback["INTERACTION_END"] = "INTERACTION_END";
    Playback["UTTERANCE"] = "UTTERANCE";
})(Playback || (Playback = {}));
export var ActorType;
(function (ActorType) {
    ActorType["UNKNOWN"] = "UNKNOWN";
    ActorType["PLAYER"] = "PLAYER";
    ActorType["AGENT"] = "AGENT";
})(ActorType || (ActorType = {}));
export var TextEventSourceType;
(function (TextEventSourceType) {
    TextEventSourceType["UNKNOWN"] = "UNKNOWN";
    TextEventSourceType["SPEECH_TO_TEXT"] = "SPEECH_TO_TEXT";
    TextEventSourceType["TYPED_IN"] = "TYPED_IN";
    TextEventSourceType["GENERATED"] = "GENERATED";
    TextEventSourceType["FILLER"] = "FILLER";
})(TextEventSourceType || (TextEventSourceType = {}));
export var ControlEventAction;
(function (ControlEventAction) {
    ControlEventAction["UNKNOWN"] = "UNKNOWN";
    ControlEventAction["AUDIO_SESSION_START"] = "AUDIO_SESSION_START";
    ControlEventAction["AUDIO_SESSION_END"] = "AUDIO_SESSION_END";
    ControlEventAction["INTERACTION_END"] = "INTERACTION_END";
    ControlEventAction["TTS_PLAYBACK_START"] = "TTS_PLAYBACK_START";
    ControlEventAction["TTS_PLAYBACK_END"] = "TTS_PLAYBACK_END";
    ControlEventAction["TTS_PLAYBACK_MUTE"] = "TTS_PLAYBACK_MUTE";
    ControlEventAction["TTS_PLAYBACK_UNMUTE"] = "TTS_PLAYBACK_UNMUTE";
    ControlEventAction["WARNING"] = "WARNING";
    ControlEventAction["SESSION_END"] = "SESSION_END";
})(ControlEventAction || (ControlEventAction = {}));
export var GestureEventType;
(function (GestureEventType) {
    GestureEventType["GREETING"] = "GREETING";
    GestureEventType["FAREWELL"] = "FAREWELL";
    GestureEventType["AGREEMENT"] = "AGREEMENT";
    GestureEventType["DISAGREEMENT"] = "DISAGREEMENT";
    GestureEventType["GRATITUDE"] = "GRATITUDE";
    GestureEventType["CELEBRATION"] = "CELEBRATION";
    GestureEventType["BOREDOM"] = "BOREDOM";
    GestureEventType["UNCERTAINTY"] = "UNCERTAINTY";
})(GestureEventType || (GestureEventType = {}));
export var EmotionEventSpaffCode;
(function (EmotionEventSpaffCode) {
    EmotionEventSpaffCode["NEUTRAL"] = "NEUTRAL";
    EmotionEventSpaffCode["DISGUST"] = "DISGUST";
    EmotionEventSpaffCode["CONTEMPT"] = "CONTEMPT";
    EmotionEventSpaffCode["BELLIGERENCE"] = "BELLIGERENCE";
    EmotionEventSpaffCode["DOMINEERING"] = "DOMINEERING";
    EmotionEventSpaffCode["CRITICISM"] = "CRITICISM";
    EmotionEventSpaffCode["ANGER"] = "ANGER";
    EmotionEventSpaffCode["TENSION"] = "TENSION";
    EmotionEventSpaffCode["TENSE_HUMOR"] = "TENSE_HUMOR";
    EmotionEventSpaffCode["DEFENSIVENESS"] = "DEFENSIVENESS";
    EmotionEventSpaffCode["WHINING"] = "WHINING";
    EmotionEventSpaffCode["SADNESS"] = "SADNESS";
    EmotionEventSpaffCode["STONEWALLING"] = "STONEWALLING";
    EmotionEventSpaffCode["INTEREST"] = "INTEREST";
    EmotionEventSpaffCode["VALIDATION"] = "VALIDATION";
    EmotionEventSpaffCode["AFFECTION"] = "AFFECTION";
    EmotionEventSpaffCode["HUMOR"] = "HUMOR";
    EmotionEventSpaffCode["SURPRISE"] = "SURPRISE";
    EmotionEventSpaffCode["JOY"] = "JOY";
})(EmotionEventSpaffCode || (EmotionEventSpaffCode = {}));
export var EmotionEventStrength;
(function (EmotionEventStrength) {
    EmotionEventStrength["UNSPECIFIED"] = "UNSPECIFIED";
    EmotionEventStrength["WEAK"] = "WEAK";
    EmotionEventStrength["STRONG"] = "STRONG";
    EmotionEventStrength["NORMAL"] = "NORMAL";
})(EmotionEventStrength || (EmotionEventStrength = {}));
export var DataChunkDataType;
(function (DataChunkDataType) {
    DataChunkDataType["UNSPECIFIED"] = "UNSPECIFIED";
    DataChunkDataType["AUDIO"] = "AUDIO";
    DataChunkDataType["ANIMATION"] = "ANIMATION";
    DataChunkDataType["SILENCE"] = "SILENCE";
    DataChunkDataType["STATE"] = "STATE";
})(DataChunkDataType || (DataChunkDataType = {}));
