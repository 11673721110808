import { Player } from './player';
var player = Player.getInstance();
var InworldPlayer = /** @class */ (function () {
    function InworldPlayer(props) {
        this.grpcAudioPlayer = props.grpcAudioPlayer;
    }
    InworldPlayer.prototype.getMute = function () {
        return player.getMute();
    };
    InworldPlayer.prototype.mute = function (mute) {
        player.setMute(mute);
    };
    InworldPlayer.prototype.stop = function () {
        this.grpcAudioPlayer.stop();
    };
    InworldPlayer.prototype.clear = function () {
        this.grpcAudioPlayer.clearQueue();
    };
    InworldPlayer.prototype.playWorkaroundSound = function () {
        player.playWorkaroundSound();
    };
    InworldPlayer.prototype.isActive = function () {
        return this.grpcAudioPlayer.getIsActive();
    };
    InworldPlayer.prototype.hasPacket = function (props) {
        return this.grpcAudioPlayer.hasPacketInQueue(props);
    };
    return InworldPlayer;
}());
export { InworldPlayer };
