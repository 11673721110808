var Player = /** @class */ (function () {
    function Player() {
        this.mediaStream = new MediaStream();
        this.audioElement = document.createElement('audio');
        this.mediaStream = new MediaStream();
        document.body.append(this.audioElement);
    }
    Player.getInstance = function () {
        if (!this.instance) {
            this.instance = new Player();
        }
        return Player.instance;
    };
    Player.prototype.setMute = function (mute) {
        if (this.audioElement) {
            this.audioElement.muted = mute;
        }
    };
    Player.prototype.getMute = function () {
        if (this.audioElement) {
            return this.audioElement.muted;
        }
        return false;
    };
    Player.prototype.setStream = function (stream) {
        this.mediaStream = stream;
        this.audioElement.srcObject = stream;
        this.audioElement.autoplay = true;
        this.audioElement.play();
    };
    Player.prototype.setTrack = function (track) {
        if (!this.audioElement.srcObject) {
            this.audioElement.srcObject = this.mediaStream;
        }
        this.mediaStream.addTrack(track);
        this.audioElement.autoplay = true;
    };
    // workaround sound for browsers with restrictive play policies;
    Player.prototype.playWorkaroundSound = function () {
        this.audioElement.play();
    };
    return Player;
}());
export { Player };
