var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { v4 } from 'uuid';
export var CHAT_HISTORY_TYPE;
(function (CHAT_HISTORY_TYPE) {
    CHAT_HISTORY_TYPE["ACTOR"] = "actor";
    CHAT_HISTORY_TYPE["TRIGGER_EVENT"] = "trigger_event";
    CHAT_HISTORY_TYPE["INTERACTION_END"] = "interaction_end";
})(CHAT_HISTORY_TYPE || (CHAT_HISTORY_TYPE = {}));
var InworldHistory = /** @class */ (function () {
    function InworldHistory() {
        this.history = [];
        this.queue = [];
    }
    InworldHistory.prototype.addOrUpdate = function (_a) {
        var _b, _c, _d, _e, _f, _g, _h, _j;
        var characters = _a.characters, grpcAudioPlayer = _a.grpcAudioPlayer, packet = _a.packet, outgoing = _a.outgoing;
        var chatItem;
        var utteranceId = (_b = packet.packetId) === null || _b === void 0 ? void 0 : _b.utteranceId;
        var interactionId = (_c = packet.packetId) === null || _c === void 0 ? void 0 : _c.interactionId;
        if (packet.isText()) {
            var id_1 = ((_e = (_d = packet.routing) === null || _d === void 0 ? void 0 : _d.source) === null || _e === void 0 ? void 0 : _e.isCharacter)
                ? (_g = (_f = packet.routing) === null || _f === void 0 ? void 0 : _f.source) === null || _g === void 0 ? void 0 : _g.name
                : (_j = (_h = packet.routing) === null || _h === void 0 ? void 0 : _h.target) === null || _j === void 0 ? void 0 : _j.name;
            var character = characters.find(function (x) { return x.getId() === id_1; });
            var actorItem = __assign(__assign({}, this.combineTextItem(packet)), { character: character });
            if (grpcAudioPlayer.hasPacketInQueue({ utteranceId: utteranceId })) {
                this.queue = __spreadArray(__spreadArray([], this.queue, true), [actorItem], false);
            }
            else {
                chatItem = actorItem;
            }
        }
        else if (packet.isTrigger()) {
            chatItem = this.combineTriggerItem(packet, outgoing);
        }
        else if (packet.isInteractionEnd()) {
            var controlItem = this.combineInteractionEndItem(packet);
            if (grpcAudioPlayer.hasPacketInQueue({ interactionId: interactionId })) {
                this.queue = __spreadArray(__spreadArray([], this.queue, true), [controlItem], false);
            }
            else {
                chatItem = controlItem;
            }
        }
        if (chatItem) {
            var currentHistoryIndex = this.history.findIndex(function (item) {
                return item.id === (chatItem === null || chatItem === void 0 ? void 0 : chatItem.id);
            });
            if (currentHistoryIndex >= 0 && chatItem) {
                this.history[currentHistoryIndex] = chatItem;
            }
            else {
                this.history = __spreadArray(__spreadArray([], this.history, true), [chatItem], false);
            }
        }
        return !!chatItem;
    };
    InworldHistory.prototype.update = function (packet) {
        if (packet.isText()) {
            var currentHistoryIndex = this.history.findIndex(function (item) { var _a; return item.id === ((_a = packet.packetId) === null || _a === void 0 ? void 0 : _a.utteranceId); });
            if (currentHistoryIndex >= 0) {
                this.history[currentHistoryIndex] = __assign(__assign({}, this.history[currentHistoryIndex]), this.combineTextItem(packet));
                return true;
            }
        }
        return false;
    };
    InworldHistory.prototype.display = function (packet, type) {
        switch (type) {
            case CHAT_HISTORY_TYPE.ACTOR:
                var foundActor_1 = this.queue.find(function (item) {
                    return item.type === CHAT_HISTORY_TYPE.ACTOR &&
                        item.id === packet.packetId.utteranceId;
                });
                if (foundActor_1) {
                    this.history = __spreadArray(__spreadArray([], this.history, true), [foundActor_1], false);
                    this.queue = __spreadArray([], this.queue, true).filter(function (item) { return item.id !== foundActor_1.id; });
                }
                return foundActor_1;
            case CHAT_HISTORY_TYPE.INTERACTION_END:
                // Find items in current interaction
                var inCurrentInteraction_1 = this.queue.filter(function (item) { return item.interactionId === packet.packetId.interactionId; });
                var onlyInteractionEnd = inCurrentInteraction_1.length === 1 &&
                    inCurrentInteraction_1[0].type === CHAT_HISTORY_TYPE.INTERACTION_END;
                // If only INTERACTION_END is left in list then move it to history
                if (onlyInteractionEnd) {
                    this.history = __spreadArray(__spreadArray([], this.history, true), [inCurrentInteraction_1[0]], false);
                    this.queue = __spreadArray([], this.queue, true).filter(function (item) { return item.id !== inCurrentInteraction_1[0].id; });
                }
                return onlyInteractionEnd;
        }
    };
    InworldHistory.prototype.get = function () {
        return this.history;
    };
    InworldHistory.prototype.filter = function (props) {
        var interactionId = props.interactionId, utteranceId = props.utteranceId;
        this.history = this.history.filter(function (item) { return !utteranceId.includes(item.id); });
        this.queue = this.queue.filter(function (item) {
            return item.interactionId !== interactionId && !utteranceId.includes(item.id);
        });
    };
    InworldHistory.prototype.clear = function () {
        this.queue = [];
        this.history = [];
    };
    InworldHistory.prototype.combineTextItem = function (packet) {
        var _a, _b, _c;
        var date = new Date(packet.date);
        var source = (_a = packet.routing) === null || _a === void 0 ? void 0 : _a.source;
        var utteranceId = (_b = packet.packetId) === null || _b === void 0 ? void 0 : _b.utteranceId;
        var interactionId = (_c = packet.packetId) === null || _c === void 0 ? void 0 : _c.interactionId;
        return {
            id: utteranceId,
            isRecognizing: !packet.text.final,
            type: CHAT_HISTORY_TYPE.ACTOR,
            text: packet.text.text,
            date: date,
            interactionId: interactionId,
            source: source,
        };
    };
    InworldHistory.prototype.combineTriggerItem = function (packet, outgoing) {
        var _a, _b, _c;
        var date = new Date(packet.date);
        var source = (_a = packet.routing) === null || _a === void 0 ? void 0 : _a.source;
        var utteranceId = (_b = packet.packetId) === null || _b === void 0 ? void 0 : _b.utteranceId;
        var interactionId = (_c = packet.packetId) === null || _c === void 0 ? void 0 : _c.interactionId;
        return {
            id: utteranceId,
            type: CHAT_HISTORY_TYPE.TRIGGER_EVENT,
            name: packet.trigger.name,
            date: date,
            interactionId: interactionId,
            outgoing: outgoing,
            source: source,
        };
    };
    InworldHistory.prototype.combineInteractionEndItem = function (packet) {
        var _a;
        var date = new Date(packet.date);
        var interactionId = (_a = packet.packetId) === null || _a === void 0 ? void 0 : _a.interactionId;
        return {
            id: v4(),
            date: date,
            interactionId: interactionId,
            type: CHAT_HISTORY_TYPE.INTERACTION_END,
        };
    };
    return InworldHistory;
}());
export { InworldHistory };
