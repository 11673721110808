/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as fm from "./fetch.pb";
export var InteractionDislikeType;
(function (InteractionDislikeType) {
    InteractionDislikeType["DISLIKE_TYPE_UNSPECIFIED"] = "DISLIKE_TYPE_UNSPECIFIED";
    InteractionDislikeType["DISLIKE_TYPE_IRRELEVANT"] = "DISLIKE_TYPE_IRRELEVANT";
    InteractionDislikeType["DISLIKE_TYPE_UNSAFE"] = "DISLIKE_TYPE_UNSAFE";
    InteractionDislikeType["DISLIKE_TYPE_UNTRUE"] = "DISLIKE_TYPE_UNTRUE";
    InteractionDislikeType["DISLIKE_TYPE_INCORRECT_USE_KNOWLEDGE"] = "DISLIKE_TYPE_INCORRECT_USE_KNOWLEDGE";
    InteractionDislikeType["DISLIKE_TYPE_UNEXPECTED_ACTION"] = "DISLIKE_TYPE_UNEXPECTED_ACTION";
    InteractionDislikeType["DISLIKE_TYPE_UNEXPECTED_GOAL_BEHAVIOR"] = "DISLIKE_TYPE_UNEXPECTED_GOAL_BEHAVIOR";
    InteractionDislikeType["DISLIKE_TYPE_REPETITION"] = "DISLIKE_TYPE_REPETITION";
})(InteractionDislikeType || (InteractionDislikeType = {}));
export var VoicePreset;
(function (VoicePreset) {
    VoicePreset["VOICE_PRESET_UNSPECIFIED"] = "VOICE_PRESET_UNSPECIFIED";
    VoicePreset["VOICE_PRESET_FEMALE_1"] = "VOICE_PRESET_FEMALE_1";
    VoicePreset["VOICE_PRESET_FEMALE_2"] = "VOICE_PRESET_FEMALE_2";
    VoicePreset["VOICE_PRESET_FEMALE_3"] = "VOICE_PRESET_FEMALE_3";
    VoicePreset["VOICE_PRESET_FEMALE_4"] = "VOICE_PRESET_FEMALE_4";
    VoicePreset["VOICE_PRESET_FEMALE_5"] = "VOICE_PRESET_FEMALE_5";
    VoicePreset["VOICE_PRESET_MALE_1"] = "VOICE_PRESET_MALE_1";
    VoicePreset["VOICE_PRESET_MALE_2"] = "VOICE_PRESET_MALE_2";
    VoicePreset["VOICE_PRESET_MALE_3"] = "VOICE_PRESET_MALE_3";
    VoicePreset["VOICE_PRESET_MALE_4"] = "VOICE_PRESET_MALE_4";
    VoicePreset["VOICE_PRESET_MALE_5"] = "VOICE_PRESET_MALE_5";
    VoicePreset["INWORLD_VOICE_PRESET_MALE_1"] = "INWORLD_VOICE_PRESET_MALE_1";
    VoicePreset["INWORLD_VOICE_PRESET_MALE_2"] = "INWORLD_VOICE_PRESET_MALE_2";
    VoicePreset["INWORLD_VOICE_PRESET_FEMALE_1"] = "INWORLD_VOICE_PRESET_FEMALE_1";
    VoicePreset["INWORLD_VOICE_PRESET_FEMALE_2"] = "INWORLD_VOICE_PRESET_FEMALE_2";
    VoicePreset["INWORLD_VOICE_PRESET_MALE_3"] = "INWORLD_VOICE_PRESET_MALE_3";
    VoicePreset["INWORLD_VOICE_PRESET_MALE_4"] = "INWORLD_VOICE_PRESET_MALE_4";
    VoicePreset["INWORLD_VOICE_PRESET_MALE_5"] = "INWORLD_VOICE_PRESET_MALE_5";
    VoicePreset["INWORLD_VOICE_PRESET_FEMALE_3"] = "INWORLD_VOICE_PRESET_FEMALE_3";
    VoicePreset["INWORLD_VOICE_PRESET_FEMALE_4"] = "INWORLD_VOICE_PRESET_FEMALE_4";
    VoicePreset["INWORLD_VOICE_PRESET_ROBOT_MALE_1"] = "INWORLD_VOICE_PRESET_ROBOT_MALE_1";
    VoicePreset["INWORLD_VOICE_PRESET_ROBOT_MALE_2"] = "INWORLD_VOICE_PRESET_ROBOT_MALE_2";
    VoicePreset["INWORLD_VOICE_PRESET_ROBOT_MALE_3"] = "INWORLD_VOICE_PRESET_ROBOT_MALE_3";
    VoicePreset["INWORLD_VOICE_PRESET_ROBOT_MALE_4"] = "INWORLD_VOICE_PRESET_ROBOT_MALE_4";
    VoicePreset["INWORLD_VOICE_PRESET_ROBOT_MALE_5"] = "INWORLD_VOICE_PRESET_ROBOT_MALE_5";
    VoicePreset["INWORLD_VOICE_PRESET_ROBOT_FEMALE_1"] = "INWORLD_VOICE_PRESET_ROBOT_FEMALE_1";
    VoicePreset["INWORLD_VOICE_PRESET_ROBOT_FEMALE_2"] = "INWORLD_VOICE_PRESET_ROBOT_FEMALE_2";
    VoicePreset["INWORLD_VOICE_PRESET_ROBOT_FEMALE_3"] = "INWORLD_VOICE_PRESET_ROBOT_FEMALE_3";
    VoicePreset["INWORLD_VOICE_PRESET_ROBOT_FEMALE_4"] = "INWORLD_VOICE_PRESET_ROBOT_FEMALE_4";
    VoicePreset["INWORLD_VOICE_PRESET_SPIKE"] = "INWORLD_VOICE_PRESET_SPIKE";
    VoicePreset["INWORLD_VOICE_PRESET_TWILIGHT"] = "INWORLD_VOICE_PRESET_TWILIGHT";
    VoicePreset["INWORLD_VOICE_PRESET_FLUTTERSHY"] = "INWORLD_VOICE_PRESET_FLUTTERSHY";
    VoicePreset["INWORLD_VOICE_PRESET_ROBOT_SPIKE"] = "INWORLD_VOICE_PRESET_ROBOT_SPIKE";
    VoicePreset["INWORLD_VOICE_PRESET_ROBOT_TWILIGHT"] = "INWORLD_VOICE_PRESET_ROBOT_TWILIGHT";
    VoicePreset["INWORLD_VOICE_PRESET_ROBOT_FLUTTERSHY"] = "INWORLD_VOICE_PRESET_ROBOT_FLUTTERSHY";
})(VoicePreset || (VoicePreset = {}));
export var PreviousDialogDialogParticipant;
(function (PreviousDialogDialogParticipant) {
    PreviousDialogDialogParticipant["UNKNOWN"] = "UNKNOWN";
    PreviousDialogDialogParticipant["PLAYER"] = "PLAYER";
    PreviousDialogDialogParticipant["CHARACTER"] = "CHARACTER";
})(PreviousDialogDialogParticipant || (PreviousDialogDialogParticipant = {}));
var WorldEngine = /** @class */ (function () {
    function WorldEngine() {
    }
    WorldEngine.CreateWorld = function (req, initReq) {
        return fm.fetchReq("/v1/worlds/".concat(req["protoWorldName"]), __assign(__assign({}, initReq), { method: "POST", body: JSON.stringify(req, fm.replacer) }));
    };
    WorldEngine.LoadScene = function (req, initReq) {
        return fm.fetchReq("/v1/".concat(req["name"], ":load"), __assign(__assign({}, initReq), { method: "POST", body: JSON.stringify(req, fm.replacer) }));
    };
    WorldEngine.LogError = function (req, initReq) {
        return fm.fetchReq("/v1/log:error", __assign(__assign({}, initReq), { method: "POST", body: JSON.stringify(req, fm.replacer) }));
    };
    WorldEngine.VoicePreview = function (req, initReq) {
        return fm.fetchReq("/v1/voice:preview", __assign(__assign({}, initReq), { method: "POST", body: JSON.stringify(req, fm.replacer) }));
    };
    WorldEngine.ListBaseVoices = function (req, initReq) {
        return fm.fetchReq("/v1/voice:base?".concat(fm.renderURLSearchParams(req, [])), __assign(__assign({}, initReq), { method: "GET" }));
    };
    WorldEngine.GenerateToken = function (req, initReq) {
        return fm.fetchReq("/auth/v1/tokens/token:generate", __assign(__assign({}, initReq), { method: "POST", body: JSON.stringify(req, fm.replacer) }));
    };
    WorldEngine.PingCharacter = function (req, initReq) {
        return fm.fetchReq("/auth/v1/character:ping", __assign(__assign({}, initReq), { method: "POST", body: JSON.stringify(req, fm.replacer) }));
    };
    WorldEngine.CreateInteractionFeedback = function (req, initReq) {
        return fm.fetchReq("/v1/".concat(req["parentsessiondefaultagentsinteractions"], "/feedback"), __assign(__assign({}, initReq), { method: "POST", body: JSON.stringify(req["feedback"], fm.replacer) }));
    };
    return WorldEngine;
}());
export { WorldEngine };
